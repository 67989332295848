import { FC, Fragment } from 'react';

import { Helmet } from 'react-helmet';

export type MetaProps = {
  noindex?: boolean;
};

const SiteMetaData: FC<MetaProps> = ({ noindex = false }) => (
  <Fragment>
    <Helmet title='Alpaca Guild' defer={false}>
      <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
      <link rel='manifest' href='/favicon/site.webmanifest' />
      <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color='#ffffff' />
      <meta name='theme-color' content='#F4FFFC' />
      <meta name='msapplication-navbutton-color' content='#F4FFFC' />
      <meta name='apple-mobile-web-app-status-bar-style' content='#F4FFFC' />
      {noindex && <meta name='robots' content='noindex' />}
    </Helmet>
  </Fragment>
);

export default SiteMetaData;
